<template>
  <div class="card">
    <div class="section" v-for="(attach, index) in attachmentList" :key="index">
      <span class="title">{{ attach.name }}</span>
      <span class="action" v-if="attach.files.length" @click="viewFile(attach)"
        >查看</span
      >
      <span class="action empty" v-else></span>
    </div>
    <van-empty v-if="attachmentList.length == 0" description="暂无附件" />
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import storage from "@/libs/storage";
import Vue from "vue";

import { Empty } from "vant";

Vue.use(Empty);

export default {
  props: {
    source: {
      type: Object,
      required: true,
    },
  },
  computed: {
    attachmentList() {

      return Object.keys(this.source || {}).map((name) => {
        return {
          name,
          files: this.source[name] || [],
        };
      });
    },
  },
  methods: {
    viewFile(attach) {
      var storeId = uuidv4();
      storage.setSession(storeId, attach);
      this.$router.push({
        name: "FileViewer",
        query: {
          storeId,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.card {
  .section {
    margin: 10px 5px 0px 5px;
    background: #fff;
    padding: 8px 10px;
    color: #333333;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .title {
      font-weight: 650;
    }
    .action {
      font-size: 12px;
      cursor: pointer;
      color: #02a7f0;
    }
  }
}
</style>
